export default {
  brand: 'OpenABA',
  tagline: 'A Community Toolkit',
  description:
    'OpenABA provides a powerful toolkit for behavior analysts, technicians, and educators to deliver and analyze care.',
  site_url: 'openaba.com',
  site_domain: 'openaba.com',
  support_email: 'help@openaba.com',
  volunteer_email: 'volunteer@openaba.com',
  team_email: 'team@openaba.com'
};

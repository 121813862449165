import React, { createContext, useState, useEffect } from 'react';
import 'firebase/analytics';
import 'firebase/firestore';
import 'firebase/performance';
import * as firebase from 'firebase';

export const firebaseConfig = {
  apiKey: 'AIzaSyDPK1AHiPc0HmZ0OpdIphwnWR3EMie9MLM',
  authDomain: 'open-aba.firebaseapp.com',
  databaseURL: 'https://open-aba.firebaseio.com',
  projectId: 'open-aba',
  storageBucket: 'open-aba.appspot.com',
  messagingSenderId: '599989629292',
  appId: '1:599989629292:web:5b5fa7d41e7b96c83f0349',
  measurementId: 'G-4EXDJ0B1PC'
};

// Initialize Firebase
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

// export const analytics = firebase.analytics()
export const firestore = firebase.firestore();
export default firebase;

export const FirebaseContext = createContext(null as any);

export const FirebaseProvider = props => {
  const [state, setState] = useState({});

  useEffect(() => {
    setState({
      perf: firebase.performance(),
      analytics: firebase.analytics(),
      registrationsRef: firebase.firestore().collection('registrations')
    });
  }, []);

  return (
    <FirebaseContext.Provider value={state}>
      {props.children}
    </FirebaseContext.Provider>
  );
};

export const serverTS = firebase.firestore.FieldValue.serverTimestamp;
export const documentIDs = firebase.firestore.FieldPath.documentId;
